import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { getEnvVariable } from '../utils/envUtils';

const supabaseUrl: string = getEnvVariable('REACT_APP_SUPABASE_URL');
const supabaseAnonKey: string = getEnvVariable('REACT_APP_SUPABASE_KEY');

export const supabase: SupabaseClient = createClient(supabaseUrl, supabaseAnonKey);

export async function addEmail(emailStr: string) {
    const { data, error } = await supabase
        .from('emails')
        .insert([{ email: emailStr }]);

    if (error) throw error;
    return data;
}