import MobileLogo from '../assets/images/logo/mobile-logo.png'
import Slider from "../components/slider";
import Header from "../components/header";

export default function LandingPage() {
    return (
        <div>
            <div id="tj-overlay-bg2" className="tj-overlay-canvas"></div>

            {/*-========== Mobile Menu Start ==============*/}
            <div className="tj-offcanvas-area">
                <div className="tj-offcanvas-header d-flex align-items-center justify-content-between">
                    <div className="logo-area text-center">
                        <img src={MobileLogo} alt="Logo" />
                    </div>
                    <div className="offcanvas-icon">
                        <a id="canva_close" href="#">
                            <i className="fa-light fa-xmark"></i>
                        </a>
                    </div>
                </div>
                {/* Canvas Mobile Menu start */}
                <nav className="right_menu_togle mobile-navbar-menu d-lg-none" id="mobile-navbar-menu"></nav>
                {/* Canvas Menu end */}
            </div>
            {/*========== Mobile Menu End ==============*/}

            <Header />
            <Slider />
        </div>
    )
}