import React, { useState } from 'react';
import { addEmail } from '../../services/supabaseService';

// Enum for button states
const ButtonState = {
    NONE: 0,
    SUCCESS: 1,
    ERROR: 2
};


const EmailForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [buttonState, setButtonState] = useState(ButtonState.NONE);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };


    const handleSubmit = async () => {
        try {
            const response = await addEmail(email);
            console.log('Response:', response);
            setButtonState(ButtonState.SUCCESS); // Set to success state
        } catch (error) {
            console.error('Error submitting email:', error);
            setButtonState(ButtonState.ERROR); // Set to error state
        }
    };

    const getButtonClass = () => {
        switch (buttonState) {
            case ButtonState.SUCCESS:
                return 'success-button';
            case ButtonState.ERROR:
                return 'error-button';
            default:
                return 'default-button';
        }
    };

    const getButtonText = () => {
        switch (buttonState) {
            case ButtonState.SUCCESS:
                return 'Bedankt';
            case ButtonState.ERROR:
                return 'Error';
            default:
                return 'Registreer nu';
        }
    };

    return (
        <div className="hero-input-form">
            <div className="hero-input">
                <input
                    type="email"
                    id="mail"
                    name="mail"
                    placeholder="Enter your email address"
                    required
                    value={email}
                    onChange={handleEmailChange}
                />
            </div>
            <div className="tj-hero-button">
                <button
                    className={`tj-primary-black-btn ${getButtonClass()}`}
                    type="button"
                    onClick={handleSubmit}
                >
                    {getButtonText()}
                </button>
            </div>
        </div>
    );
};

export default EmailForm;
