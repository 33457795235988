import MobileLogo from "../assets/images/logo/mobile-logo.png";


export default function Header() {
    return (
        <header className="tj-header-area" id="tj-header-sticky">
            <div className="container">
                <div className="row">
                    <div className="header-content-area">
                        <div className="logo-area">
                            <img src={MobileLogo} alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}