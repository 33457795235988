import BgShapes from "../assets/images/banner/bg-group5.svg";
import AvatarRed from "../assets/images/avatars/red.svg";
import AvatarBlue from "../assets/images/avatars/blue.svg";
import AvatarGreen from "../assets/images/avatars/green.svg";
import AvatarYellow from "../assets/images/avatars/yellow.svg";
import MovingShape from "../assets/images/shape/sec-shape14.svg";
import EmailForm from "./input/emailForm";

export default function Slider() {
    return (
        <div>
            <section className="tj-slider-section" style={{ backgroundImage: `url(${BgShapes})` }}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="slider-content-area">
                                <div className="tj-sec-heading">
                                    <h1 className="title">
                                        AI-gedreven marketing voor <span className="active-color"> lokale bedrijven</span>
                                    </h1>
                                    <p className="desc">
                                        Een AI-gedreven digitaal marketingplatform voor Nederlandse MKB-bedrijven met beperkte tijd of middelen. Dit platform ontwikkelt en implementeert op maat gemaakte marketingstrategieën. Bedrijven kunnen hierdoor op een betaalbare en efficiënte wijze hun online zichtbaarheid vergroten, waardoor ze meer tijd overhouden voor hun kernactiviteiten.
                                    </p>
                                    <div className="hero-middle-area">
                                        <EmailForm />
                                        <div className="header-list">
                                            <ul className="list-gap">
                                                <li><i className="flaticon-checkmark"></i> Geen spam</li>
                                                <li><i className="flaticon-checkmark"></i> Geen verplichtingen</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="slider-box">
                                <div className="slider-area">
                                    <div className="red">
                                        <img src={AvatarRed} alt="Description" />
                                    </div>
                                    <div className="blue">
                                        <img src={AvatarBlue} alt="Description" />
                                    </div>
                                    <div className="green">
                                        <img src={AvatarGreen} alt="Description" />
                                    </div>
                                    <div className="yellow">
                                        <img src={AvatarYellow} alt="Description" />
                                    </div>
                                </div>
                                <div className="slider-shape shake-y">
                                    <img src={MovingShape} alt="Shape" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tj-circle-box-one">
                    <span className="circle_1"></span>
                    <span className="circle_2"></span>
                    <span className="circle_3"></span>
                    <span className="circle_4"></span>
                    <span className="circle_5"></span>
                    <span className="circle_6"></span>
                    <span className="circle_7"></span>
                    <span className="circle_8"></span>
                    <span className="circle_9"></span>
                </div>
                <div className="tj-circle-box-one tj-circle-box-one-1">
                    <span className="circle_1"></span>
                    <span className="circle_2"></span>
                    <span className="circle_3"></span>
                    <span className="circle_4"></span>
                    <span className="circle_5"></span>
                    <span className="circle_6"></span>
                    <span className="circle_7"></span>
                    <span className="circle_8"></span>
                    <span className="circle_9"></span>
                </div>
            </section>
        </div>
    )
}